<!-- 分步骤add -->
<template>
  <ykc-form class="el-form-wrap">
    <el-steps :active="active" align-center finish-status="success">
      <el-step title="机构信息" />
      <el-step title="推送配置" />
    </el-steps>
    <div>
      <ykc-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm0"
        class="el-form-wrap"
        v-show="active === 0">
        <ykc-form-item label="机构名称" prop="name">
          <ykc-input
            placeholder="中文、英文、数字（1~30字数），不可重复"
            maxlength="30"
            v-model="ruleForm.name"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="公司名称" prop="companyName">
          <ykc-input
            placeholder="请输入公司名称"
            maxlength="30"
            v-model="ruleForm.companyName"></ykc-input>
        </ykc-form-item>
        <ykc-form-item
          class="img-error"
          label="统一社会信用代码"
          prop="socialCreditCode"
          bottomTip="温馨提示：请认真核对营业执照上的18位编码">
          <ykc-input
            placeholder="请输入统一社会信用代码"
            maxlength="18"
            v-model="ruleForm.socialCreditCode"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="机构管理员" prop="manageName">
          <ykc-input
            placeholder="请输入机构管理员"
            maxlength="30"
            v-model="ruleForm.manageName"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="联系方式" prop="contactInfo">
          <ykc-input
            placeholder="请输入机构管理员联系方式"
            maxlength="11"
            v-model="ruleForm.contactInfo"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="启充金额" prop="startLimitAmount">
          <ykc-input
            placeholder="请输入启充金额"
            maxlength="11"
            v-model="ruleForm.startLimitAmount"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="停充金额" prop="stopLimitAmount">
          <ykc-input
            placeholder="请输入停充金额"
            maxlength="11"
            v-model="ruleForm.stopLimitAmount"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="机构类型" prop="orgType">
          <ykc-radio
            :data="orgTypeData"
            :disabled="isEdit"
            @change="handleConfigType"
            v-model="ruleForm.orgType"></ykc-radio>
        </ykc-form-item>
        <ykc-form-item label="监管平台" prop="supervisePlat" v-if="ruleForm.orgType === '4'">
          <ykc-dropdown
            :remote="true"
            :data="supervisePlatData"
            v-model="ruleForm.supervisePlat"
            :disabled="isEdit"></ykc-dropdown>
        </ykc-form-item>
      </ykc-form>
      <ykc-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm1"
        class="el-form-wrap"
        v-if="active === 1">
        <ykc-form-item label="接口URL" prop="interconnectionUrl">
          <ykc-input placeholder="请输入接口URL" v-model="ruleForm.interconnectionUrl"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="对账选择" prop="reconciliationType">
          <ykc-radio
            :data="[
              //  { id: '1', name: '接口自动' },
              { id: '2', name: '线下手动' },
            ]"
            @change="handleConfigType"
            v-model="ruleForm.reconciliationType"></ykc-radio>
        </ykc-form-item>
        <ykc-form-item label="价格推送" prop="pricePush">
          <ykc-radio
            :data="feeData"
            @change="handleConfigType"
            v-model="ruleForm.pricePush"></ykc-radio>
        </ykc-form-item>
        <!-- <ykc-form-item label="实时数据费用" prop="realTimeFee">
          <ykc-radio
            :data="feeData"
            @change="handleConfigType"
            v-model="ruleForm.realTimeFee"></ykc-radio>
        </ykc-form-item> -->
        <!-- <ykc-form-item label="充电订单费用" prop="chargeOrderFee">
          <ykc-radio
            :data="feeData"
            @change="handleConfigType"
            v-model="ruleForm.chargeOrderFee"></ykc-radio>
        </ykc-form-item> -->
        <ykc-form-item label="电量小数" prop="powerDecimal">
          <ykc-radio
            :data="[
              { id: '2', name: `两位` },
              { id: '3', name: '三位' },
            ]"
            @change="handleConfigType"
            v-model="ruleForm.powerDecimal"></ykc-radio>
        </ykc-form-item>
        <ykc-form-item label="设备状态" prop="stationStatus">
          <ykc-radio
            :data="statusData"
            @change="handleConfigType"
            v-model="ruleForm.stationStatus"></ykc-radio>
        </ykc-form-item>
        <ykc-form-item label="启动充电推送" prop="startChargeResult">
          <ykc-radio
            :data="pushData"
            @change="handleConfigType"
            v-model="ruleForm.startChargeResult"></ykc-radio>
        </ykc-form-item>
        <ykc-form-item label="结束充电推送" prop="stopChargeResult">
          <ykc-radio
            :data="pushData"
            @change="handleConfigType"
            v-model="ruleForm.stopChargeResult"></ykc-radio>
        </ykc-form-item>
        <ykc-form-item label="实时数据" prop="realTimeData">
          <ykc-radio
            :data="pushData"
            @change="handleConfigType"
            v-model="ruleForm.realTimeData"></ykc-radio>
        </ykc-form-item>
        <ykc-form-item label="充电订单信息" prop="chargeOrder">
          <ykc-radio
            :data="pushData"
            @change="handleConfigType"
            v-model="ruleForm.chargeOrder"></ykc-radio>
        </ykc-form-item>
        <!-- <ykc-form-item label="实时推送频率" prop="realTimeDataRate">
          <ykc-input
            placeholder="请输入推送频率"
            maxlength="30"
            v-model="ruleForm.realTimeDataRate">
            <template slot="append">秒</template>
          </ykc-input>
        </ykc-form-item> -->
      </ykc-form>
    </div>
  </ykc-form>
</template>
<script>
  import regexpObj from '@/utils/regexp';
  import { filterByIdList } from '@/utils/index';
  import { unioneOrg, customerOrg } from '@/service/apis';
  import { loadDicItem } from '@/utils/dictionary';

  export default {
    props: {
      active: {
        type: Number,
        default: 0,
      },
      isEdit: {
        type: Boolean,
        default: false,
      },
      id: {
        type: Number,
      },
    },
    name: 'AddOrEdit',
    data() {
      return {
        name: '',
        socialCreditCode: '',
        detail: {},
        organizations: [],
        supervisePlatData: [], // 监管平台数据
        orgTypeData: [
          { id: '3', name: '流量平台' },
          { id: '4', name: '监管平台' },
        ],
        feeData: [
          { id: '1', name: '标准价' },
          { id: '2', name: '优惠价' },
        ],
        statusData: [
          { id: '0', name: '变位推送' },
          { id: '1', name: '不推送' },
        ],
        pushData: [
          { id: '0', name: '不推送' },
          { id: '1', name: '推送' },
        ],

        ruleForm: {
          name: '',
          companyName: '',
          socialCreditCode: '',
          manageName: '',
          contactInfo: '',
          startLimitAmount: '',
          stopLimitAmount: '',
          orgType: 0,
          supervisePlat: '', // 监管平台
          interconnectionUrl: '',
          reconciliationType: '',
          pricePush: '',
          realTimeFee: '',
          chargeOrderFee: '',
          powerDecimal: '',
          stationStatus: '',
          startChargeResult: '',
          stopChargeResult: '',
          realTimeData: '',
          realTimeDataRate: null,
          chargeOrder: '',
        },
        rules: {
          name: [
            { required: true, message: '请输入机构名称', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.nameType.regexp,
              message: regexpObj.regexp.input.nameType.errorTips.error('机构名称'),
            },
            { validator: this.onlyCheckName, trigger: 'blur' },
          ],
          companyName: [{ required: true, message: '请输入公司名称', trigger: 'blur' }],
          socialCreditCode: [
            { required: true, message: '请输入有效的统一社会信用代码', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.creditCode.regexp,
              message: regexpObj.regexp.input.creditCode.errorTips.error('社会统一信用代码'),
            },
            { validator: this.onlyCheckCreditCode, trigger: 'blur' },
          ],
          manageName: [{ required: true, message: '请输入机构管理员名称', trigger: 'blur' }],
          contactInfo: [
            { required: true, message: '请输入机构管理员联系方式', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.cellphoneType.regexp,
              message: regexpObj.regexp.input.cellphoneType.errorTips.error('机构管理员联系方式'),
            },
          ],
          startLimitAmount: [
            { required: true, message: '请输入启充金额', trigger: 'change' },
            {
              pattern: regexpObj.regexp.input.decimalNumberType_2.regexp,
              message: regexpObj.regexp.input.decimalNumberType_2.errorTips.error('金额', 99.99),
              trigger: 'blur',
            },
          ],
          stopLimitAmount: [
            { required: true, message: '请输入停充金额', trigger: 'change' },
            {
              pattern: regexpObj.regexp.input.decimalNumberType_2.regexp,
              message: regexpObj.regexp.input.decimalNumberType_2.errorTips.error('金额', 99.99),
              trigger: 'blur',
            },
          ],
          orgType: [{ required: true, message: '请选择机构类型', trigger: 'change' }],
          supervisePlat: [{ required: true, message: '请选择监管平台', trigger: 'change' }],
          interconnectionUrl: [{ required: true, message: '请输入接口URL', trigger: 'blur' }],
          reconciliationType: [{ required: true, message: '请选择机构类型', trigger: 'change' }],
          pricePush: [{ required: true, message: '请选择价格推送方式', trigger: 'change' }],
          realTimeFee: [{ required: true, message: '请选择实时数据费用', trigger: 'change' }],
          chargeOrderFee: [{ required: true, message: '请选择充电订单费用', trigger: 'change' }],
          orpowerDecimalgType: [{ required: true, message: '请选择电量小数', trigger: 'change' }],
          stationStatus: [{ required: true, message: '请选择设备状态', trigger: 'change' }],
          startChargeResult: [{ required: true, message: '请选择启动充电推送', trigger: 'change' }],
          stopChargeResult: [{ required: true, message: '请选择结束充电推送', trigger: 'change' }],
          realTimeData: [{ required: true, message: '请选择实时数据', trigger: 'change' }],
          realTimeDataRate: [{ required: true, message: '请输入实时推送频率', trigger: 'blur' }],
          chargeOrder: [{ required: true, message: '请选择充电订单信息', trigger: 'change' }],
        },
      };
    },
    created() {
      this.supervisePlatData = loadDicItem('supervise_plat');
      // 编辑回显
      if (this.id) {
        unioneOrg
          .detail({ id: this.id })
          .then(res => {
            this.ruleForm = {
              ...this.ruleForm,
              ...res,
            };
            this.name = res.name;
            this.socialCreditCode = res.socialCreditCode;
          })
          .catch(() => {});
      }
    },
    computed: {
      /**
       * 车队列表默认展示符
       * */
      organizationPlaceHolder() {
        if (this.organizations.length) {
          return '请输入归属车队';
        }
        return '暂无数据';
      },
      formData() {
        const formData = {
          ...this.ruleForm,
        };
        formData.startLimitAmount = Number(formData.startLimitAmount);
        formData.stopLimitAmount = Number(formData.stopLimitAmount);
        formData.id = Number(formData.id);
        console.log(JSON.stringify(formData));

        return formData;
      },
    },
    methods: {
      filterByIdList,
      /**
       * 切换配置方式
       */
      handleConfigType(e) {
        console.log(e, 'e---handleConfigType');
        // 请求树
      },
      /**
       * 清除文件
       */
      handleRemove(propName) {
        this.ruleForm[propName] = [];
      },
      /**
       * 名称唯一性校验
       */
      onlyCheckName(rule, value, callback) {
        if (value) {
          if (this.ruleForm.name === this.name) {
            callback();
          } else {
            customerOrg
              .checkOrgRepeat({ name: value })
              .then(res => {
                console.log(JSON.stringify(res));
                if (res && this.ruleForm.name !== this.name) {
                  callback(new Error('名称重复'));
                } else {
                  callback();
                }
              })
              .catch(() => {});
          }
        }
      },
      /**
       * 统一社会信用代码唯一性校验
       */
      onlyCheckCreditCode(rule, value, callback) {
        if (value) {
          customerOrg
            .checkOrgRepeat({ socialCreditCode: value })
            .then(res => {
              if (res && this.ruleForm.socialCreditCode !== this.socialCreditCode) {
                callback(new Error('该统一社会信用代码已经被占用'));
              } else {
                this.nameValidError = '';
                callback();
              }
            })
            .catch(() => {});
        }
      },
      /** 表单提交事件，用于父组件定义的"完成"或"保存"按钮点击事件 */
      submitForm() {
        return new Promise(resolve => {
          this.validateAllForm().then(() => {
            if (!this.id) {
              unioneOrg
                .add(this.formData)
                .then(res => {
                  console.log('新增', res);
                  this.$message({
                    message: '保存成功',
                    type: 'success',
                  });
                  resolve();
                })
                .catch(() => {});
            } else {
              unioneOrg
                .edit(this.formData)
                .then(res => {
                  console.log('编辑', res);
                  this.$message({
                    message: '保存成功',
                    type: 'success',
                  });
                  resolve();
                })
                .catch(() => {});
            }
          });
        });
      },
      /** 校验当前表格是否通过 */
      validateForm() {
        return new Promise((resolve, reject) => {
          this.$refs[`ruleForm${this.active}`].validate(valid => {
            if (valid) {
              resolve();
            } else {
              reject();
            }
          });
        });
      },
      validateAllForm() {
        return new Promise((resolve, reject) => {
          Promise.all(
            Object.keys(this.$refs)
              .filter(o => o.includes('ruleForm'))
              .map(o => {
                return new Promise((_resolve, _reject) => {
                  this.$refs[o].validate(valid => {
                    if (valid) {
                      _resolve();
                    } else {
                      _reject();
                    }
                  });
                });
              })
          )
            .then(() => {
              resolve();
            })
            .catch(() => {
              reject();
            });
        });
      },
    },
  };
</script>
