<!--
 * @ Author: Clark
 * @ Create Time: 2023-02-05 20:20:57
 * @ Modified by: Your name
 * @ Modified time: 2023-03-03 11:00:55
 * @ Description: 司机管理>互联互通客户>互联客户管理
 -->

<template>
  <scroll-layout>
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div class="motorcade-main-account">
      <ykc-table
        ref="YkcTable"
        operateFixedType="right"
        :data="tableData"
        :title="tableTitle"
        :columns="tableColumns"
        :operateButtons="tableOperateButtons">
        <template #headerButtons>
          <div class="table-header-button">
            <template v-for="(btn, i) in pageButtons">
              <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handleClick">
                {{ btn.text }}
              </ykc-button>
            </template>
          </div>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.size"
          :current-page.sync="pageInfo.current"
          @size-change="handlePaginationSizeChange"
          @current-change="handlePaginationCurrentChange"></ykc-pagination>
      </ykc-table>
    </div>
    <ykc-drawer
      :show.sync="showDrawer"
      :title="drawerTitle"
      :on-close="onAddDrawerClose"
      :before-cancel="onAddDrawerCancel"
      ensure-txt="保存">
      <template #footer>
        <div class="drawer-button-wrap">
          <template v-for="(btn, i) in drawerButtons">
            <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
              {{ btn.label }}
            </ykc-button>
          </template>
        </div>
      </template>
      <div class="drawer-main-body" v-if="showDrawer">
        <AddOrEdit
          v-if="drawerType === 'edit'"
          ref="addOrEdit"
          :id="id"
          :isEdit="isEdit"
          :active="stepsIndex"
          :isAddChildren="isAddChildren"></AddOrEdit>
      </div>
    </ykc-drawer>
  </scroll-layout>
</template>

<script>
  import YkcDialog from '@/components/ykc-ui/dialog/index';
  import AddOrEdit from './AddOrEdit.vue';
  import { unioneOrg } from '@/service/apis';
  import { offlineExport, code } from '@/utils';

  export default {
    name: 'MotorcadeMainAccountList',
    components: {
      AddOrEdit,
    },
    data() {
      return {
        id: '',
        drawerType: '',
        stepsIndex: 0,
        maxStepIndex: 1,
        isEdit: false,
        showDrawer: false,
        addChildren: false, // 添加主机构 false 添加子机构 true
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => true,
            handler: () => {
              YkcDialog({
                dialogType: 'feedback',
                showTitle: false,
                showFooter: true,
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.showDrawer = false; // 隐藏抽屉
                },
              });
            },
          },
          {
            label: '上一步',
            enabled: () => {
              return this.stepsIndex !== 0;
            },
            disabled: () => false,
            handler: () => {
              this.stepsIndex--;
            },
          },
          {
            label: '下一步',
            enabled: () => {
              return this.stepsIndex !== this.maxStepIndex;
            },
            disabled: () => false,
            handler: () => {
              this.$refs.addOrEdit.validateForm().then(() => {
                this.stepsIndex++;
              });
            },
          },
          {
            label: '保存',
            enabled: () => {
              return this.stepsIndex === this.maxStepIndex;
            },
            disabled: () => {
              return this.isRequest;
            },
            handler: () => {
              this.isRequest = true;
              this.$refs.addOrEdit
                .submitForm()
                .then(() => {
                  this.stepsIndex = 0;
                  this.showDrawer = false;
                  this.isRequest = false;
                  this.searchTableList();
                })
                .finally(() => {
                  this.isRequest = false;
                });
            },
          },
        ],
        pageButtons: [
          {
            id: '0',
            text: '新增',
            type: 'plain',
            enabled: () => code('union:org:add'),
            handleClick: () => {
              this.add();
            },
          },
          {
            id: '1',
            text: '导出',
            type: 'plain',
            enabled: () => code('union:org:export'),
            handleClick: () => {
              this.doExportFile();
            },
          },
        ],
        tableData: [],
        tableTitle: '客户列表',
        tableColumns: [
          { label: '客户编码', prop: 'id', minWidth: '100px' },
          { label: '机构名称', prop: 'name', minWidth: '100px' },
          { label: '统一社会信用代码', prop: 'socialCreditCode', minWidth: '100px' },
          { label: '机构类型', prop: 'orgType', minWidth: '100px' },
          { label: '机构管理员', prop: 'manageName', minWidth: '100px' },
          { label: '联系方式', prop: 'contactInfo', minWidth: '100px' },
        ],
        tableOperateButtons: [
          {
            render: (h, data, { row }) =>
              code('union:org:edit') ? (
                <ykc-button
                  type="text"
                  onClick={() => {
                    this.add(row.id);
                  }}>
                  编辑
                </ykc-button>
              ) : null,
          },
          {
            render: (h, data, { row }) =>
              code('union:org:detail') ? (
                <ykc-button
                  type="text"
                  onClick={() => {
                    this.detailLink(row);
                  }}>
                  详情
                </ykc-button>
              ) : null,
          },
        ],
        pageInfo: {
          current: 0,
          size: 10,
        },
        searchParams: {
          orgType: '', // 3互联互通客户
          name: '',
          socialCreditCode: '',
          manageName: '',
          contactInfo: '',
        },
      };
    },
    created() {
      this.searchTableList();
      const { type } = this.$route.query;
      if (type === 'jump') {
        setTimeout(() => {
          this.add();
        }, 500);
      }
    },
    computed: {
      drawerTitle() {
        if (this.drawerType === 'import') {
          return '批量导入';
        }
        return ` ${this.isEdit ? '编辑' : '新增'}互联机构`;
      },
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'name',
            label: '机构名称',
            placeholder: '请输入站点名称',
          },
          {
            comName: 'YkcInput',
            key: 'socialCreditCode',
            label: '统一社会信用代码',
            placeholder: '请输入统一社会信用代码',
          },
          {
            comName: 'YkcInput',
            key: 'contactInfo',
            label: '联系方式',
            placeholder: '请输入联系方式',
          },
          {
            comName: 'YkcInput',
            key: 'manageName',
            label: '联系人',
            placeholder: '请输入联系人',
          },
          {
            comName: 'YkcDropdown',
            key: 'orgType',
            label: '机构类型',
            data: [
              { id: '3', name: '流量平台' },
              { id: '4', name: '监管平台' },
            ],
          },
        ];
      },
    },
    methods: {
      detailLink(row) {
        this.$router.push({
          path: '/customerCenter/customerUnion/customerUnion/customerUnionDetail',
          query: {
            id: row.id,
          },
        });
      },
      add(id) {
        this.stepsIndex = 0;
        this.maxStepIndex = 1;
        this.showAddDrawer = true;
        this.isEdit = !!id;
        this.id = id || null;
        this.drawerType = 'edit';
        this.showDrawer = true;
        this.isAddChildren = false;
      },
      handleCommand(e) {
        console.log(JSON.stringify(e));
      },
      handleSpecialCountClick(row) {
        const routeUrl = this.$router.resolve({
          path: '/priceConfig/specialPurchasePrice',
          query: {
            stationId: row.stationId,
          },
        });
        window.open(routeUrl.href, '_blank');
      },
      changeStateSearch() {
        this.pageInfo.current = 0;
        this.searchTableList();
      },
      /** 根据条件查询数据 */
      searchTableList() {
        Promise.all([
          unioneOrg.list({
            ...this.pageInfo,
            ...this.searchParams,
          }),
        ]).then(([res1]) => {
          this.tableData = res1.records;
          this.pageInfo.total = res1.total || 0;
        });
      },
      /**
       * @desc 导出文件 - 车队主账号列表
       * */
      doExportFile() {
        console.log('doExport');
        offlineExport(
          {
            downloadType: 'zdl_org',
            jsonNode: {
              ...this.searchParams,
            },
          },
          this.pageInfo.total
        );
      },
      /** 批量导入文件 */
      doImportFile() {
        this.drawerType = 'import';
        this.isEdit = true;
        this.showDrawer = true;
      },
      onAddDrawerClose(done) {
        this.stepsIndex = 0;
        done();
      },
      /**
       * @desc 点击抽屉取消按钮 callback
       * */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            done();
            drawerDone();
            if (this.isEdit) {
              this.isEdit = false;
            }
          },
        });
      },
      /**
       * @desc 点击页面左上角按钮 callback
       * */
      handlePageButtonClick(button, event) {
        if (typeof button.handleClick === 'function') {
          button.handleClick(button, event);
        }
      },
      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(form) {
        this.pageInfo.current = 0;
        Object.assign(this.searchParams, form);
        this.searchTableList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.searchTableList();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.searchTableList();
      },
      /**
       * 重置 form data
       * */
      resetFormData() {
        this.ruleForm = {
          orgType: '', // 3互联互通客户
          name: '',
          socialCreditCode: '',
          manageName: '',
          contactInfo: '',
        };
      },
    },
  };
</script>
<style lang="scss" scoped>
  .link {
    color: blue;
    cursor: pointer;
  }
  .el-radio-group .item {
    border: 0;
  }
</style>
